import React, { useEffect, useRef, useState } from "react"
import styles from "./ServicesList.module.scss"
import { Link, useLocation, useParams } from "react-router-dom"
import { selectAuth } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import LocalizedLink from "../../hoc/LocalizedLink"
import { getApiUrl } from "../../utils/getApiUrl"
import clsx from "clsx"

interface Props {
  size?: string
  title?: any
  subtitle?: any
  background?: any
  img?: string
  id: string
}

const ServiceEl: React.FC<Props> = ({ size, title, subtitle, background, img, id }) => {
  const { object_id } = useParams()
  const location = useLocation()
  const user = useAppSelector(selectAuth)
  // const subtitleRef = useRef<HTMLElement>(null)
  // const [heightOfBlock, setHeightOfBlock] = useState(0)
  // const [countLines, setCountLines] = useState(0)

  // useEffect(() => {
  //   if (subtitleRef.current && subtitleRef.current.lastChild?.textContent?.length && subtitleRef.current.lastChild?.textContent === subtitle) {
  //     // console.log(subtitleRef.current.lastChild?.textContent === subtitle);
  //     const blockWidth = subtitleRef.current.clientWidth
  //     const length = subtitleRef.current.lastChild?.textContent?.length
  //     const midleWidthLetter = 9.5
  //     const quntitiLettersInLine = Math.round(blockWidth / midleWidthLetter)

  //     // setCountLines(Math.ceil(length / quntitiLettersInLine))
  //     setCountLines((prev: any) => {
  //       console.log(Math.ceil(length / quntitiLettersInLine));
  //       return Math.ceil(length / quntitiLettersInLine)
  //     })

  //     if (window.document.documentElement.clientWidth < 640) {
  //       setHeightOfBlock(96)
  //     } else setHeightOfBlock(120)

  //     window.addEventListener("resize", () => {
  //       if (window.document.documentElement.clientWidth < 640) {
  //         setHeightOfBlock(96)
  //       } else setHeightOfBlock(120)
  //     })
  //   }
  // }, [subtitleRef])

  return (
    <LocalizedLink
      to={object_id ? `/object/${object_id}/category/${id}` : `/category/${id}`}
      style={{ background: background }}
      className={clsx(styles.serviceEl, size === "wide" && styles.serviceEl__big)}
      onClick={(event: any) => {
        if (!user?.user?.id) {
          event.preventDefault()
        }
      }}
    >
      {img && <img src={img ? getApiUrl() + "/public/photo/" + img : ""} alt="" />}
      <div className={styles.serviceEl__content}>
        <h4>{title}</h4>
        <p
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
      </div>
    </LocalizedLink>
  )
}

export default ServiceEl
