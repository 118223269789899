import { api } from "./index"
import { BaseResponseType, IServiceItem } from "../../types/content"
import { IOrder, IOrderShort, ITechObj, ITechObjInfo } from "../../types/orderTypes"

export const mainApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<IServiceItem[], void>({
      query: () => ({
        url: "/public/service-categories/selectlist",
        method: "GET",
      }),
      transformResponse: (response: BaseResponseType<IServiceItem[]>) => response.data,
    }),
    getServiceInfo: builder.query<
      BaseResponseType<{
        description: string
        id: string
        image_id: string | null
        is_allow_prorose_new_service: boolean
        name: string
      }>,
      string
    >({
      query: (id) => ({
        url: `/public/service-categories/${id}`,
        method: "GET",
      }),
    }),
    /*Получить услуги*/
    getServices: builder.mutation<
      BaseResponseType<{
        aItems: IOrderShort[]
        sIsEnd: boolean
        iCount: number
      }>,
      {
        limit?: number
        offset?: number
        keywords?: string
        technicalObjects?: string[]
        categories?: string[]
      }
    >({
      query: (data) => ({
        url: `/public/services/getlist`,
        method: "POST",
        body: data,
      }),
    }),
    getProductInfo: builder.query<IOrder, string>({
      query: (id) => ({
        url: `/public/services/${id}`,
        method: "GET",
      }),
      transformResponse: (response: BaseResponseType<IOrder>) => response.data,
    }),
    postReqForNewProduct: builder.mutation<any, { text: string; technical_object_id?: string }>({
      query: (data) => ({
        url: `/public/services/propose`,
        method: "POST",
        body: data,
      }),
    }),
    getTechObjectList: builder.mutation<any, void>({
      query: (data) => ({
        url: `/public/technical-object/getlist`,
        method: "POST",
        body: data,
      }),
    }),
    getServicesByTechObj: builder.mutation<
      BaseResponseType<{
        serviceCategories: {
          id: string
          name: string
          services: IOrderShort[]
        }[]
        technicalObject: ITechObjInfo
      }>,
      { technical_object_id: string; category_id?: string }
    >({
      query: (data) => ({
        url: `/public/technical-object/services/${data.technical_object_id}`,
        method: "POST",
        body: {
          category_id: data.category_id,
        },
      }),
    }),
    getCategoriesByTechObj: builder.mutation<
      any,
      { technical_object_id: string; keywords?: string; category_id?: string }
    >({
      query: ({ technical_object_id, keywords, category_id }) => ({
        url: `/public/technical-object/service-categories/${technical_object_id}`,
        method: "POST",
        body: {
          category_id: category_id,
          keywords: keywords,
        },
      }),
    }),
    getServicesSubCat: builder.mutation<
      BaseResponseType<{
        serviceCategories: {
          id: string
          name: string
          services: IOrderShort[]
        }[]
      }>,
      { technicalObjects?: string[]; category_id: string; keywords?: string; limit?: number; offset?: number }
    >({
      query: (data) => ({
        url: `/public/services/getsubcatlist`,
        method: "POST",
        body: data,
      }),
    }),
    getTechObject: builder.query<ITechObjInfo, string>({
      query: (technical_object_id) => ({
        url: `/public/technical-object/${technical_object_id}`,
        method: "GET",
      }),
      transformResponse: (response: BaseResponseType<ITechObjInfo>) => response.data,
    }),
    // Дочерние элементы объекта
    getChildObjects: builder.query<ITechObj[], { serviceId: string; parentId?: string; keywords?: string }>({
      query: ({ serviceId, parentId, keywords }) => ({
        url: `/public/services/get-to-childs/${serviceId}${
          keywords ? `?keywords=${keywords}` : `?parent_id=${parentId}`
        }`,
        method: "GET",
      }),
      transformResponse: (response: BaseResponseType<{ objects: ITechObj[] }>) => response.data.objects,
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCategoriesQuery,
  useGetServicesMutation,
  useLazyGetServiceInfoQuery,
  useGetProductInfoQuery,
  useLazyGetProductInfoQuery,
  usePostReqForNewProductMutation,
  useGetTechObjectListMutation,
  useGetCategoriesByTechObjMutation,
  useGetServicesSubCatMutation,
  useGetTechObjectQuery,
  useLazyGetChildObjectsQuery,
} = mainApi
