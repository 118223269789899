import { FC } from "react"
import LocalizedLink from "../../hoc/LocalizedLink"
import { ArrowLeftBoldIcon } from "../../icons/ArrowLeftBoldIcon"
import clsx from "clsx"
import styles from "./TitleBack.module.scss"

interface Props {
  title?: string
  searchVal?: string | number
  setSearchVal?: any
  onClick?: any
  noLinkBack?: boolean
  className?: string
  link?: string
}

const TitleBack: FC<Props> = ({ title, setSearchVal, searchVal, onClick, noLinkBack, link, className }) => {
  return (
    <div className={clsx(styles.titleBack, className)}>
      {noLinkBack ? (
        <div
          className={`titleBack__back ${styles.titleBack__back}`}
          onClick={() => (typeof onClick === "function" ? onClick() : null)}
        >
          <ArrowLeftBoldIcon />
        </div>
      ) : (
        <LocalizedLink
          className={`titleBack__back ${styles.titleBack__back}`}
          to={link || "/"}
          onClick={() => {
            if (searchVal) setSearchVal("")
            // if (location.pathname !== "/") navigate("/")
          }}
        >
          <ArrowLeftBoldIcon />
        </LocalizedLink>
      )}

      <h2 className={styles.titleBack__text}>{title}</h2>
    </div>
  )
}

export default TitleBack
