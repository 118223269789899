import LocalizedLink from "../../../hoc/LocalizedLink"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import ArticlesList from "../ArticlesList/ArticlesList"
import { useAppSelector } from "../../../hooks"
import { selectUser } from "../../../redux/slice/auth"
import { useLazyGetArticlesQuery } from "../../../redux/api/articles"
import clsx from "clsx"
import useWindowSize from "../../../hooks/useWindowSize"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import styles from "./ArticlesAside.module.scss"

const ArticlesAside = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const user = useAppSelector(selectUser)
  const { isDesktop } = useWindowSize()

  const [getArticles, { data: articles, isLoading, isUninitialized }] = useLazyGetArticlesQuery()

  useEffect(() => {
    if (!user?.id || !isUninitialized) return
    getArticles()
  }, [user?.id, isUninitialized])

  return (
    <>
      {user?.id && (
        <>
          {isDesktop && (
            <LocalizedLink to={"/"} className={styles.btn}>
              <IconBtn icon={"back"} borderSize={"circle"} type={"span"} className={styles.iconBack} />
              <span>
                {t("mainMenu")}
                <span className={styles.btnTxt}>{t("goBack")}</span>
              </span>
            </LocalizedLink>
          )}

          {isLoading ? (
            <div className={clsx("skeletonBlock", styles.loader)} />
          ) : (
            <>{articles && <ArticlesList articles={articles} />}</>
          )}
        </>
      )}
    </>
  )
}

export default ArticlesAside
