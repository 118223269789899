// import { configDotenv } from "dotenv";
// import 'dotenv/config'
// console.log(configDotenv)
// require('dotenv').config({ path: '.env' });
// console.log(process);

export const IS_DEV = process.env.NODE_ENV === "development"
export const PAGE_TITLE = process.env.REACT_APP_PUBLIC_PAGE_TITLE || "Staq"
export const OG_TITLE = process.env.REACT_APP_PUBLIC_OG_TITLE || "Личный кабинет клиента"
export const OG_DESCRIPTION =
  process.env.REACT_APP_PUBLIC_OG_DESCRIPTION ||
  "Инновационный каталог услуг для вашего бизнеса и взаимодействия с контрагентами"
export const EMAIL_REGEXP =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
export const PASS_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&+~()^?`-])[A-Za-z\d@$!%*#?&+~()^?`-]{8,}$/

// export const BACKEND_HOST = process.env.NEXT_PUBLIC_BACKEND_HOST
// export const USER_ID_COOKIE = 'user_id'
export const USER_TOKEN_COOKIE = "tokenServices"
export const USER_ID_COOKIE = "userID"
export const USER_OBJECT_COOKIE = "userObj"
export const MENU_LIST_COOKIE = "listMenu"
export const MAX_AGE_TOKEN_180_DAYS_IN_SECONDS = 60 * 60 * 24 * 30 * 6 //30 дней
export const videoFormats = ["mp4", "3gp", "wmv", "mpeg", "flv", "webm", "avi", "mkv", "3gpp", "mpg", "m4v"]
export const LANG_LIST = [
  {
    text: "English",
    value: "en",
  },
  // {
  //   text: "ภาษาไทย",
  //   value: "th",
  // },
  // {
  //   text: "China",
  //   value: "cn",
  // },
  // {
  //   text: "Français",
  //   value: "fr",
  // },
  {
    text: "Русский",
    value: "ru",
  },
]
