import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { appHeight } from "../../utils/helpers"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "../../hooks"
import { registerSW } from "../../utils/serviceWorkerRegistration"
import FooterSupport from "./FooterSupport/FooterSupport"
import clsx from "clsx"
import LocalizedLink from "../../hoc/LocalizedLink"
import useWindowSize from "../../hooks/useWindowSize"
import styles from "./footer.module.scss"
import { selectParams } from "../../redux/slice/params"

const Footer = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const location = useLocation()
  const { isDesktop } = useWindowSize()

  const { serviceListLoading } = useAppSelector((state) => state.main)
  const { companyLogo } = useAppSelector(selectParams)
  // const currentLang = params?.locale
  // const token = getCookie(USER_TOKEN_COOKIE)
  // const dispatch = useAppDispatch()

  useEffect(() => {
    appHeight()

    // Запись в кэш изображений
    const imgs = document.getElementsByTagName("img")
    const imgsSrc: string[] = []
    // console.log(imgs);
    for (let i = 0; i < imgs.length; i++) {
      const src = imgs[i].getAttribute("src") ?? ""
      if (!imgsSrc.includes(src) && !src.includes("blob") && !src.includes("googleusercontent")) imgsSrc.push(src)
    }
    registerSW()
    if (typeof caches !== "undefined") {
      caches?.open("my-cache-v1")?.then(function (cache) {
        return cache.addAll(imgsSrc)
      })
    }
    //

    //   // Выводить модалку выбора языка если нет выбранного языка в url и юзер не залогинен
    //   // setTimeout(()=>i18n.changeLanguage(currentLang ? currentLang : "en"), 10)
    //   i18n.changeLanguage(currentLang ? currentLang : "en")
    //   if ((!currentLang || currentLang === "dev") && !token) {
    //     dispatch(languageModalOpen({ flag: true }))
    //   }

    window.addEventListener("resize", appHeight)
    return () => window.removeEventListener("resize", appHeight)
  }, [])

  return !isDesktop && location.pathname === "/" && serviceListLoading ? null : (
    <footer className={clsx("footer", styles.footer)}>
      <div className={`footer__wp ${styles.footer__wp}`}>
        <div className={styles.footer__info}>
          {companyLogo.data && (
            <LocalizedLink to={"/"} className={`footer__logoWrap ${styles.footer__logoWrap}`}>
              {companyLogo.type === "image/svg+xml" ? (
                <span dangerouslySetInnerHTML={{ __html: companyLogo.data }} />
              ) : (
                <img src={companyLogo.data} alt={"logo"} />
              )}
            </LocalizedLink>
          )}
          <p>{t("txtTechnicalSupport")}.</p>
        </div>
        <FooterSupport />
      </div>
    </footer>
  )
}

export default Footer
