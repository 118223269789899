import axios from "axios"

const uploadFile: ({
  file,
  reader,
  updatePhotoArr,
  updateAttachmetsArr,
  setIsLoading,
  scrollToBottomChat,
  siteUrl,
}: {
  file: any
  reader: any
  updatePhotoArr: any
  updateAttachmetsArr: any
  setIsLoading: any
  scrollToBottomChat: any
  siteUrl: string
}) => void = async ({
  file,
  reader,
  updatePhotoArr,
  updateAttachmetsArr,
  setIsLoading,
  scrollToBottomChat,
  siteUrl,
}) => {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("name", reader.name)
  formData.append("resolvedImmediately", "false")

  const randomIdStr = "abcdefghijklmnopqrstuvwxyz1234567"
  let newId = ""
  for (let i = 0; i < 8; i++) {
    newId += randomIdStr[Math.round(Math.random() * (randomIdStr.length - 1))]
  }

  let uploadPrefix = "attachment"
  if (reader.type === "image/jpeg" || reader.type === "image/png" || reader.type === "image/gif") {
    uploadPrefix = "photo"
  }
  const createAtFile = new Date().valueOf() / 1000
  try {
    await axios
      .post(`${siteUrl}${process.env.REACT_APP_PUBLIC_API_SUFFIX}/public/${uploadPrefix}`, formData, {
        onUploadProgress: (upload) => {
          if (upload?.total) {
            const uploadloadProgress = Math.round((100 * upload.loaded) / upload.total)
            scrollToBottomChat()
            setIsLoading(true)

            if (uploadloadProgress < 100) {
              if (uploadPrefix === "attachment") {
                updateAttachmetsArr({
                  frontId: newId,
                  filename: reader.name,
                  preview: file,
                  progress: uploadloadProgress,
                  size: reader.size,
                  createdAt: createAtFile,
                })
              } else {
                updatePhotoArr({
                  frontId: newId,
                  preview: file,
                  filename: reader.name,
                  progress: uploadloadProgress,
                  size: reader.size,
                  createdAt: createAtFile,
                })
              }
            }
          }
        },
      })
      .then((result: any) => {
        scrollToBottomChat()
        if (uploadPrefix === "attachment") {
          updateAttachmetsArr({
            id: result.data.attachmentId,
            frontId: newId,
            filename: reader.name,
            progress: 100,
            preview: file,
            size: reader.size,
            createdAt: createAtFile,
          })
        } else {
          updatePhotoArr({
            id: result.data.photoId,
            frontId: newId,
            filename: reader.name,
            progress: 100,
            preview: file,
            size: reader.size,
            createdAt: result.data.createdAt,
          })
        }
        setTimeout(() => {
          setIsLoading(false)
        }, 300)
      })
  } catch (error) {
    console.warn(error)
  }
}

export default uploadFile
