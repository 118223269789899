import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getApiUrl } from "../../utils/getApiUrl"

// https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery

/*const endpointsWithoutAuthorization = [
  'login',
  'register',
  'emailVerify',
  'resendEmailVerify',
  'setRole',
  'resetPassword',
  'createLid',
  'getCatalogTeams',
]*/

const endpointsFormData = ["createProject", "updateUser", "sendMessage", "postCreateOrder"]

const endpointsWithoutAuthorization = [
  "login",
  "register",
  "emailVerify",
  "resendEmailVerify",
  "setRole",
  "resetPassword",
  "createLid",
  "getCatalogTeams",
]

const staqBaseQuery = async (args: any, api: any, extraOptions: any) => {
  const defaultResult: any = await fetchBaseQuery({
    baseUrl: `${args.isWeb ? getApiUrl() + "/web/" : getApiUrl()}`,
    credentials: "include",
    prepareHeaders: (headers, { endpoint, ...api }) => {
      const currentLang = localStorage.getItem("i18nextLng") || "en"

      if (!endpointsWithoutAuthorization.includes(endpoint)) {
        headers.set("Accept-Language", currentLang)
      }

      // headers.set('Accept', 'application/json')
      if (!endpointsFormData.includes(endpoint)) {
        headers.set("Content-Type", "application/json")
      }
      return headers
    },
  })(args, api, extraOptions)

  return {
    ...defaultResult,
  }
}

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["Orders"],
  baseQuery: staqBaseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    // if (action.type === HYDRATE) {
    return action?.payload?.[reducerPath]
    // }
  },
  endpoints: () => ({}),
})
