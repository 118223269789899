import { useEffect, useMemo, useState, useRef, FC } from "react"
import TitleBack from "../TitleBack/TitleBack"
import Search from "../Search/Search"
import SearchList from "../SearchList/SearchList"
import { useParams } from "react-router-dom"
import { useGetServicesMutation, useGetTechObjectQuery, useLazyGetServiceInfoQuery } from "../../redux/api/main"
import { updateProductsList, setSubcatsList, resetSubcatsList } from "../../redux/slice/products"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { selectIsAllowProposeNewServiceMain, updateIsAllowNewServiceCategory } from "../../redux/slice/auth"
import { useGetServicesSubCatMutation } from "../../redux/api/main"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperOptions } from "swiper/types/swiper-options"
import { FreeMode, Navigation } from "swiper/modules"
import { useTranslation } from "react-i18next"
import SliderNav from "../Assets/SliderNav/SliderNav"
import useWindowSize from "../../hooks/useWindowSize"
import clsx from "clsx"
import SearchListLoader from "../SearchList/SearchListLoader"
import type { Swiper as SwiperClass } from "swiper/types"
import NoFindBtn from "../NoFindBtn/NoFindBtn"
import BtnObject from "../Assets/BtnObject/BtnObject"
import styles from "./ProductsLayout.module.scss"

interface Props {}

const swiperParams: SwiperOptions = {
  slidesPerView: "auto",
  modules: [FreeMode, Navigation],
  freeMode: true,
  observer: true,
  observeParents: true,
  observeSlideChildren: true,
}

const ProductsLayout: FC<Props> = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const currentLang = localStorage.getItem("i18nextLng") || "en"
  const dispatch = useAppDispatch()
  const { isDesktop, size } = useWindowSize()
  const queries = useParams()

  const [searchVal, setSearchVal] = useState<string | number>("")
  const [pageTitle, setPageTitle] = useState<string>("")
  const [numActiveLink, setNumActiveLink] = useState<null | number>(0)
  const [isSliderWrapFixed, setSliderWrapFixed] = useState<boolean>(false)

  const swiperRef = useRef<any>(null)
  const catList = useRef<any>(null)
  const catSliderWrapRef = useRef<HTMLDivElement>(null)
  const serviceItemsContainer = useRef<null | NodeListOf<HTMLElement>>(null)
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)

  const [getServiceInfo] = useLazyGetServiceInfoQuery()
  const [getServices, { isLoading: isLoadingServices }] = useGetServicesMutation()
  const [getServicesSubCat] = useGetServicesSubCatMutation()
  const { data: techObjData } = useGetTechObjectQuery(queries?.object_id as string, { skip: !queries?.object_id })

  const { productsList, subCatsList } = useAppSelector((state) => state.products)
  const isAllowProposeNewServiceMain = useAppSelector(selectIsAllowProposeNewServiceMain)

  useEffect(() => {
    if (subCatsList?.length > 0 && !searchVal) {
      serviceItemsContainer.current = catList?.current?.querySelectorAll(`.searchList-item`)
    }
  }, [catList?.current?.querySelectorAll(`.searchList-item`), subCatsList, serviceItemsContainer, searchVal])

  const scrollToItem = (num: number) => {
    const heightOfHeader = window.innerWidth > 640 ? 112 : 70

    if (serviceItemsContainer?.current && serviceItemsContainer.current[num]) {
      const scrollToo = serviceItemsContainer?.current[num].offsetTop - heightOfHeader
      window.scrollTo(0, scrollToo)
    }
  }

  const scrollTrigger = () => {
    const scrollMenu = document.querySelector<HTMLElement>(`.${styles.roomLayout__cats}`)
    const windowWidth = window.innerWidth

    const serviceNodeList = serviceItemsContainer.current
    const heightOfHeader = windowWidth > 640 ? 112 : 70

    serviceNodeList?.forEach((el, i) => {
      const sw = swiperRef.current.swiper
      // - 1 слайд при перключении
      if (window.scrollY + heightOfHeader > el.offsetTop - 5 && window.scrollY < el.offsetTop + el.offsetHeight) {
        setNumActiveLink(i)
        sw.slideTo(i)
      } else if (
        window.scrollY < serviceNodeList[0].offsetTop ||
        window.scrollY >
          serviceNodeList[serviceNodeList.length - 1].offsetTop +
            serviceNodeList[serviceNodeList.length - 1].offsetHeight
      )
        setNumActiveLink(0)
    })

    //меню с ссылками
    if (scrollMenu && window.innerWidth < 1200) {
      const valuePx = windowWidth <= 640 ? 110 - window.scrollY : 166 - window.scrollY
      if ((windowWidth <= 640 && valuePx <= 0) || (windowWidth > 640 && valuePx <= 0)) {
        // scrollMenu.style.top = `${windowWidth <= 640 ? 0 : 0}px`
        scrollMenu.style.borderBottom = "1px solid #b3b8bb"
        return
      } else {
        // scrollMenu.style.top = `${valuePx}px`
        scrollMenu.style.borderBottom = "none"
      }
    }
    // const top = catSliderWrapRef.current?.getBoundingClientRect().top
    // if (top && window.innerWidth >= 1200) setSliderWrapFixed(top <= 64)
  }

  useEffect(() => {
    document.addEventListener("scroll", scrollTrigger)
    document.addEventListener("resize", scrollTrigger)
    scrollTrigger()

    return () => {
      document.removeEventListener("scroll", scrollTrigger)
      document.removeEventListener("resize", scrollTrigger)
      dispatch(resetSubcatsList())
    }
  }, [])

  useEffect(() => {
    scrollTrigger()
  }, [size])

  useEffect(() => {
    getServices({
      categories: queries?.category_id ? [queries.category_id] : undefined,
      technicalObjects: queries?.object_id ? [queries.object_id] : undefined,
    })
      .unwrap()
      .then((result) => {
        dispatch(updateProductsList({ list: result?.data?.aItems ?? [] }))
      })
      .catch((err) => console.error(err))

    getServicesSubCat({
      category_id: queries?.category_id || "",
      technicalObjects: queries?.object_id ? [queries.object_id] : undefined,
    }).then((res) => dispatch(setSubcatsList(res)))

    getServiceInfo(queries?.category_id || "")
      .then((result: any) => {
        setPageTitle(result?.data?.data?.name ?? "")
        dispatch(updateIsAllowNewServiceCategory(result?.data?.data?.is_allow_prorose_new_service))
      })
      .catch((err: any) => console.error(err))

    window.scrollTo(0, 0)
  }, [currentLang, queries])

  const resultSearchList = useMemo(
    () => productsList.filter(({ name }: any) => name?.toLowerCase().includes(String(searchVal).toLowerCase())),
    [searchVal, productsList],
  )

  // Категория для сервисов без категории
  const withoutCats = useMemo(() => {
    let freeElements: any = []
    subCatsList?.forEach(({ services }: any) => {
      if (typeof services === "object") {
        freeElements = [...freeElements, ...services.map((sr: any) => sr.id)]
      }
    })
    return productsList.filter(({ id }: any) => !freeElements?.includes(id))
  }, [subCatsList, productsList])

  return (
    <>
      <TitleBack
        title={pageTitle}
        className={clsx("TitleBack", queries?.object_id && styles.titleTechObj)}
        link={queries?.object_id && `/object/${queries?.object_id}`}
      />

      {techObjData?.id && <BtnObject txt={techObjData.name} type={"div"} mode={"white"} className={styles.techObj} />}

      <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={isLoadingServices} />
      <div
        style={{ display: subCatsList?.length && !searchVal ? "block" : "none" }}
        className={clsx(styles.roomLayout__cats, isSliderWrapFixed && styles["roomLayout__cats--fixed"])}
        ref={catSliderWrapRef}
      >
        <div className={styles.roomLayout__catsWp}>
          {isDesktop && (
            <SliderNav className={clsx("category-layout-slider-nav", styles.nav)} prevRef={prevRef} nextRef={nextRef} />
          )}
          <div className={styles.roomLayout__catsWrap}>
            {!isLoadingServices && (
              <>
                <Swiper
                  {...swiperParams}
                  ref={swiperRef}
                  onBeforeInit={(swiper: SwiperClass) => {
                    swiper.params.navigation.prevEl = prevRef.current
                    swiper.params.navigation.nextEl = nextRef.current
                    swiper.navigation.init()
                    swiper?.navigation?.update()
                  }}
                >
                  {subCatsList?.map((el: any, kk: number) => (
                    <SwiperSlide
                      onClick={() => scrollToItem(kk)}
                      key={el.id}
                      className={`${styles["roomLayout__catsWrap-anchor"]} ${
                        numActiveLink === kk ? `${styles["roomLayout__catsWrap-anchor-active"]}` : ""
                      }`}
                    >
                      <div>{el.name}</div>
                    </SwiperSlide>
                  ))}
                  {!!withoutCats.length && (
                    <SwiperSlide
                      onClick={() => scrollToItem(subCatsList.length)}
                      className={`${styles["roomLayout__catsWrap-anchor"]} ${
                        numActiveLink === subCatsList.length ? `${styles["roomLayout__catsWrap-anchor-active"]}` : ""
                      }`}
                    >
                      <div>{t("other")}</div>
                    </SwiperSlide>
                  )}
                </Swiper>
              </>
            )}
          </div>
        </div>
      </div>

      <div ref={catList}>
        {isLoadingServices ? (
          <SearchListLoader />
        ) : (
          <>
            {subCatsList?.length > 0 && !searchVal ? (
              <>
                {subCatsList?.map((subCat: any) => (
                  <SearchList
                    isSearchLoading={isLoadingServices}
                    key={subCat?.id}
                    title={subCat?.name ?? ""}
                    list={subCat?.services}
                    catId={subCat?.id}
                    className={styles.roomLayout__searchList}
                  />
                ))}
                {!!withoutCats.length && (
                  <SearchList
                    isSearchLoading={isLoadingServices}
                    title={t("other")}
                    list={withoutCats}
                    catId={"otherCategory"}
                    className={styles.roomLayout__searchList}
                  />
                )}
              </>
            ) : (
              <SearchList list={resultSearchList} isSearchLoading={isLoadingServices} searchVal={searchVal} />
            )}
          </>
        )}
      </div>
      {!isDesktop && isAllowProposeNewServiceMain && (
        <div style={{ marginTop: "12px" }}>
          <NoFindBtn isLoading={isLoadingServices} />
        </div>
      )}
    </>
  )
}

export default ProductsLayout
