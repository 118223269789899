import { Dispatch, FC, Fragment, ReactNode, SetStateAction, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import { selectOpenOrder, setNeedClearNewMessageCount } from "../../redux/slice/ordersList"
import { addAllMessage, selectAllMessage, updateCountUnreadMessages, updateReadMessages } from "../../redux/slice/main"
// import { useGetCountUnreadMessagesMutation } from "../../redux/api/content"
import { getApiUrl } from "../../utils/getApiUrl"
import uploadFile from "../../utils/upload/uploadFile"
import { v4 as uuidv4 } from "uuid"
import styles from "../Modals/ChatModal/ChatModal.module.scss"
import TitleBack from "../TitleBack/TitleBack"
import ChatDay from "../Modals/ChatModal/ChatDay"
import ChatMessage from "../Modals/ChatModal/ChatMessage"
import UploadBox from "../Assets/UploadBox/UploadBox"
import ReactTextareaAutosize from "react-textarea-autosize"
import AttachmentItemChat from "../Modals/ChatModal/assets/AttachmentItemChat"
import { SendPaperIcon } from "../../icons/SendPaperIcon"
import ScrollBlock from "../Assets/ScrollBlock"
import { Scrollbars } from "react-custom-scrollbars-2"
import clsx from "clsx"
import { videoFormats } from "../../utils/constants"
import ImagesGallery from "../Assets/ImagesGallery/ImagesGallery"
import { useMarkReadMessageMutation, useSendChatMessageMutation } from "../../redux/api/chat"

interface Props {
  chatID: string
  orderInfo: any
  isFirstLoadMess: boolean
  setOpenChat?: any
  emptyTxt?: string
  isChatList?: boolean
  setUnreadMessList?: Dispatch<SetStateAction<string[]>>
  handleCreateNewChat?: any
  className?: string
}

const Chat: FC<Props> = ({
  chatID,
  isChatList,
  orderInfo,
  isFirstLoadMess,
  setOpenChat,
  emptyTxt,
  setUnreadMessList,
  handleCreateNewChat,
  className,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const [message, setMessage] = useState("")
  const [photoArr, setPhotoArr] = useState<any[]>([])
  const [attachmetsArr, setAttachmetsArr] = useState<any[]>([])
  const [focusedInput, setFocusedInput] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const nowUser = useAppSelector(selectUser)
  //TODO: remove
  // const openOrder = useAppSelector(selectOpenOrder)
  const allMessage = useAppSelector(selectAllMessage)

  const gallery = useRef<any>(false)

  const [photoGallery, setPhotoGallery] = useState<any>([])
  const [galleryOpen, setGalleryOpen] = useState(false)

  const siteUrl = getApiUrl()?.replace("/api/v1", "") ?? window?.location?.origin

  const [sendChatMessage] = useSendChatMessageMutation()
  const [markReadedMessage] = useMarkReadMessageMutation()
  //TODO
  // const [getCountUnreadMessages] = useGetCountUnreadMessagesMutation()
  const scrollbarRef = useRef<Scrollbars>(null)
  const isFirstScroll = useRef<any>(false)
  const isFirstIdNewMess = useRef<any>(false)
  const idNewMess = useRef<any>(false)

  const updatePhotoArr = (data: any) => {
    setPhotoArr((prev) => [...prev.filter((f: any) => f.frontId !== data.frontId), data])
  }

  const updateAttachmetsArr = (data: any) => {
    setAttachmetsArr((prev) => [...prev.filter((f: any) => f.frontId !== data.frontId), data])
  }

  const removePhoto = (id: string) => {
    setPhotoArr((prev) => [...prev.filter((f: any) => f.id !== id)])
  }

  const removeFile = (id: string) => {
    setAttachmetsArr([...attachmetsArr.filter((f: any) => f.id !== id)])
  }

  const elementIsViewport = (element: string) => {
    const nowScrollbar = scrollbarRef?.current?.container

    const el = nowScrollbar?.querySelector(element)
    if (nowScrollbar && el) {
      const isViewTop = el?.getBoundingClientRect()?.top - nowScrollbar.getBoundingClientRect().top
      const isViewBottom = el?.getBoundingClientRect().top - nowScrollbar.getBoundingClientRect().top + el?.clientHeight
      return (
        (isViewTop > 0 && isViewTop < nowScrollbar.clientHeight) ||
        (isViewBottom > 0 && isViewBottom < nowScrollbar.clientHeight)
      )
    } else {
      return false
    }
  }

  useEffect(() => {
    //TODO: isChatList remove
    // if (typeof window.Echo === "undefined" || !chatID || !isChatList) return
    if (typeof window.Echo === "undefined" || !chatID) return
    const channel = window.Echo.join(`chats.${chatID}`)
    channel?.listen(".sendMassageFromChanel", (event: any) => {
      dispatch(addAllMessage(event))
    })

    return () => {
      window.Echo.leave(`chats.${chatID}`)
    }
  }, [chatID, isChatList])

  useEffect(() => {
    if (allMessage.length > 0 && scrollbarRef?.current) {
      const nowScrollbar = scrollbarRef?.current?.container
      if (
        isFirstLoadMess ||
        scrollbarRef?.current?.getScrollHeight() -
          scrollbarRef?.current?.getScrollTop() -
          nowScrollbar?.clientHeight ===
          0 ||
        elementIsViewport(".chatMessage:last-child") ||
        elementIsViewport(".chatMessage:nth-last-child(-n+2)")
      ) {
        const newM = nowScrollbar?.querySelector(".chatNewMess")
        if (isFirstLoadMess && isFirstScroll.current === false && newM) {
          scrollbarRef?.current?.scrollTop(
            newM.getBoundingClientRect()?.top - nowScrollbar?.getBoundingClientRect().top,
          )
          isFirstScroll.current = true
        } else {
          // scrollbarRef?.current?.scrollToBottom(isFirstLoadMess ? 350 : 0)
          scrollbarRef?.current?.scrollToBottom()
        }
      }
    }
    const removeIds: string[] = [...allMessage.filter((el: any) => !el.is_readed).map((el: any) => el.id)]

    if (removeIds.length > 0) {
      setTimeout(() => {
        markReadedMessage({
          ids: removeIds,
        })
        // Установка прочитанных сообщений
        dispatch(updateReadMessages(removeIds))
        if (setUnreadMessList) setUnreadMessList(removeIds)
        dispatch(setNeedClearNewMessageCount(String(orderInfo?.id)))
      }, 400)
    }
  }, [allMessage, scrollbarRef])

  const scrollToBottomChat = () => {
    if (
      scrollbarRef?.current &&
      scrollbarRef.current.getScrollHeight() -
        scrollbarRef.current.getScrollTop() -
        scrollbarRef.current.container?.clientHeight ===
        0
    ) {
      setTimeout(() => {
        scrollbarRef.current?.scrollToBottom()
      }, 350)
    }
  }

  const pastData = (event: any) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items
    let blob: any = null

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === 0) {
        blob = items[i].getAsFile()
      }
    }

    if (blob !== null) {
      const reader = new FileReader()
      reader.onload = function (event) {
        //  todo Добавить загрузчик файлов, урлы те же, что и на админке, только тут отсутсвует /web в урле
        uploadFile({
          file: reader.result,
          reader: blob,
          updatePhotoArr,
          updateAttachmetsArr,
          setIsLoading,
          scrollToBottomChat,
          siteUrl,
        })
      }
      reader.readAsDataURL(blob)
    }
  }

  const sendMessage = () => {
    if (message.trim().length > 0 || photoArr.length > 0 || attachmetsArr.length > 0) {
      const idMess = uuidv4()

      const messageData = {
        id: idMess,
        chat_dialog_id: "",
        user: {
          id: nowUser.id,
          name: nowUser.name,
          avatar_id: nowUser.avatar_id,
          addPhoneNumber: "",
          company_id: "",
          departmentName: "",
          email: "",
          externalId: "",
          folderName: "",
          folder_id: "",
          isAdmin: false,
          is_blocked: false,
          lastLogin: "",
          login: "",
          phoneNumber: "",
          position: "",
          roles: [],
          skills: [],
          work_schedule_id: "",
        },
        text: message,
        photos: [...photoArr.map((el: any) => el.id)],
        photos_info: [
          ...photoArr.map((el: any) => {
            return {
              createdAt: el.createdAt,
              filename: el.filename,
              size: el.size,
              id: el.id,
              previewId: el.id,
            }
          }),
        ],
        attachments: [...attachmetsArr.map((el: any) => el.id)],
        attachments_info: [
          ...attachmetsArr.map((el: any) => {
            return {
              id: el.id,
              filename: el.filename,
              size: el.size,
              createdAt: el.createdAt,
            }
          }),
        ],
        created_at: new Date().getTime(),
        is_readed: false,
        readed_at: null,
      }

      setMessage("")
      setAttachmetsArr([])
      setPhotoArr([])

      const mes = {
        id: chatID,
        data: {
          id: idMess,
          text: message,
          attachments: [...attachmetsArr.map((el: any) => el.id)],
          photos: [...photoArr.map((el: any) => el.id)],
        },
      }

      if (!chatID) {
        if (handleCreateNewChat) {
          handleCreateNewChat(messageData, mes, orderInfo?.userID, orderInfo?.name)
        }
        return
      }

      dispatch(addAllMessage(messageData))

      // window.Echo?.connector?.pusher?.channel(`presence-chats.${chatID}`)?.trigger("client-addMessage", messageData)
      window.Echo?.connector?.pusher?.channel(`presence-chats.${chatID}`)

      sendChatMessage(mes)
    }
  }

  const stopVideo = () => {
    const allVideos = document.querySelectorAll(".image-gallery-slides video")
    if (allVideos.length) {
      allVideos.forEach((el: any) => el.pause())
    }
  }
  // апдейт фотогалереи
  const galleryUpdate = (photoInfo: any) => {
    setPhotoGallery([
      ...photoInfo.map((photoEl: any) => {
        const fileName = photoEl?.filename?.split(".")?.pop()?.toLowerCase() ?? ""
        return {
          original: `${getApiUrl() + "/public/photo/" + photoEl?.id}`,
          thumbnail: `${getApiUrl() + "/public/photo/" + photoEl?.previewId}`,
          id: photoEl?.id,
          isVideo: videoFormats.some((el) => el === fileName),
        }
      }),
    ])
  }

  // Открыть фото
  const openPhoto = (photoInfo: any, kk: number) => {
    galleryUpdate(photoInfo)
    setTimeout(() => {
      setGalleryOpen(true)
      gallery.current.slideToIndex(kk)
    }, 50)
  }

  return (
    <>
      <div className={clsx(styles.chatModal, className)}>
        {setOpenChat && (
          <div className={styles.chatModal__head}>
            <TitleBack title={orderInfo?.name} onClick={() => setOpenChat(false)} noLinkBack />
          </div>
        )}
        <ScrollBlock className={`${styles.chatModal__main}`} hideTracksWhenNotNeeded ref={scrollbarRef}>
          <div className={`${styles.chatModal__mainListWp}`}>
            {!allMessage?.length && emptyTxt && <p className={styles.emptyTxt}>{emptyTxt}</p>}

            <div className={styles.chatModal__mainList}>
              {allMessage.map((el: any, kk: number) => {
                if (
                  kk !== 0 &&
                  idNewMess.current === false &&
                  isFirstIdNewMess.current !== true &&
                  el?.is_readed === false &&
                  el?.user?.id !== nowUser.id
                ) {
                  idNewMess.current = el.id
                  isFirstIdNewMess.current = false
                }

                return (
                  <Fragment key={el.id}>
                    {idNewMess.current === el.id && (
                      <div className={styles.chatModal__newMessage}>
                        <span>{t("newMessage")}</span>
                      </div>
                    )}
                    <ChatDay prevEl={allMessage[kk - 1] || false} nowEl={el} />
                    <ChatMessage message={el} nowUserId={nowUser?.id} openPhoto={openPhoto} />
                  </Fragment>
                )
              })}
            </div>
          </div>
        </ScrollBlock>
        <div className={styles.chatModal__bottom}>
          {/* <label className={styles.chatModal__append}>
              <img src="/img/clip.svg" alt="" />
              <FileDropzone name={"attachments"} setFieldValue={formik.setFieldValue} values={formik.values} />
          </label> */}
          <UploadBox
            updatePhotoArr={updatePhotoArr}
            updateAttachmetsArr={updateAttachmetsArr}
            setFocusedInput={setFocusedInput}
            scrollToBottomChat={scrollToBottomChat}
            setIsLoading={setIsLoading}
          />
          <div className={styles.chatModal__inputWp}>
            <ReactTextareaAutosize
              minRows={1}
              maxRows={3}
              placeholder={t("enterMessage")}
              value={message}
              className={styles.chatModal__input}
              onFocus={() => {
                scrollToBottomChat()
              }}
              onChange={(ev: any) => {
                setMessage(ev.target.value)
              }}
              onKeyDown={(ev: any) => {
                if (ev.keyCode === 13 && !ev.shiftKey) {
                  ev.preventDefault()
                  sendMessage()
                }
              }}
              onPaste={(ev: any) => {
                pastData(ev)
              }}
            />
            <div className={styles.chatUpload}>
              {photoArr.length > 0 ? (
                <div className={styles.chatUpload__photos}>
                  {photoArr.map((el: any) => {
                    return (
                      <div className={styles.chatUpload__photo} key={el.id + Math.random() * 1000}>
                        <img
                          src={`${
                            el.preview
                              ? el.preview
                              : `${getApiUrl()}${process.env.NEXT_PUBLIC_API_SUFFIX}/photo/${el.id}`
                          }`}
                          alt={el.filename}
                        />
                        {el.progress && el.progress !== 100 && (
                          <div className={styles.chatUpload__photoProgress}>
                            {/* <CircleProgress progress={el.progress} /> */}
                            <span>{el.progress}%</span>
                          </div>
                        )}
                        <div
                          className={styles.chatUpload__removeImage}
                          onClick={() => {
                            removePhoto(el.id)
                          }}
                        >
                          <img src="/img/close.svg" alt="" />
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : null}
              {attachmetsArr.length > 0 ? (
                <div className={styles.chatUpload__attachments}>
                  {attachmetsArr.map((el: any, kk) => {
                    return (
                      <AttachmentItemChat
                        key={el.id + Math.random() * 1000}
                        el={el}
                        iter={kk}
                        fileDelete={removeFile}
                        isAction={true}
                        isLoading={el.progress}
                      />
                    )
                  })}
                </div>
              ) : null}
            </div>
          </div>
          <button className={styles.chatModal__submit} onClick={() => sendMessage()}>
            <SendPaperIcon />
          </button>
        </div>
      </div>
      <div
        className={`${styles.chatMessage__gallery} ${
          galleryOpen ? styles.chatMessage__gallery_open : ""
        } defect-gallery`}
        onClick={(e: any) => {
          if (e.target?.classList?.contains("defect-gallery")) {
            setGalleryOpen(false)
          }
        }}
      >
        <ImagesGallery
          items={photoGallery}
          // @ts-ignore
          ref={gallery}
          onSlide={() => {
            stopVideo()
            const videoEl: any = document.querySelector(".image-gallery-slides .center video")
            if (videoEl) videoEl.play()
          }}
          renderItem={(item: any) => {
            return item.isVideo ? (
              <video controls={true} className="image-gallery-image">
                <source src={item.original} />
              </video>
            ) : (
              <img className="image-gallery-image" alt="alt" src={item?.original} />
            )
          }}
        />
      </div>
    </>
  )
}

export default Chat
