import { useCheckTokenMutation, useGetKerbTokenMutation } from "../redux/api/auth"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../hooks"
import { authModalOpen } from "../redux/slice/authModal"
import { languageModalOpen } from "../redux/slice/languageModal"
import { getKeyLangMap, langWithCurrentMomentCode } from "../utils/helpers"
import { useTranslation } from "react-i18next"
import { Loader } from "../components/Assets/Loader/Loader"
import { setIsLoginPending } from "../redux/slice/auth"
import { useScrollBlock } from "../hooks/useScrollBlock"

const WithAuth = () => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [getKerbToken] = useGetKerbTokenMutation()
  const [checkToken, test] = useCheckTokenMutation({
    fixedCacheKey: "authCacheKey",
  })
  const dispatch = useAppDispatch()
  const currentLang = localStorage.getItem("i18nextLng")
  const { i18n } = useTranslation()
  const { allowScroll } = useScrollBlock()

  const userLang = navigator.language
  const keyLang = getKeyLangMap(userLang)

  const loginDefault = () => {
    dispatch(authModalOpen({ flag: true }))
    if (!currentLang || currentLang === "dev" || !langWithCurrentMomentCode.get(currentLang)) {
      setTimeout(() => i18n.changeLanguage(keyLang ? keyLang[0] : "en"), 10)
    }
    dispatch(setIsLoginPending(false))
  }

  // Действия при не залогиненном пользователе
  const noAuthUser = () => {
    getKerbToken()
      .unwrap()
      .then((response: any) => {
        if (response?.user) {
          // console.log('2')
          // allowScroll(true)
          // dispatch(authModalOpen({ flag: false }))
        } else {
          loginDefault()
        }
      })
      .catch((err) => {
        loginDefault()
      })
  }

  useEffect(() => {
    // if (token) {
    checkToken().then(
      (result: any) => (!result?.data?.data?.user ? noAuthUser() : null), //Если есть токен, но юзер не найден
    )
    // } else {
    //   noAuthUser()
    // }
    setLoading(false)
  }, [])

  return isLoading ? <Loader /> : null
}

export default WithAuth
